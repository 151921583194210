<template>
  <v-dialog
    :value="dlg.visible"
    width="900"
    persistent
  >
    <v-card
      class="white"
      flat
    >
      <v-card-title>
        <span>{{ dlg.title }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="onClose"
        >
          <v-icon>
            {{ btnClose.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card
        class="overflow-y-auto overflow-x-hidden blue-grey lighten-5 pa-6"
        flat
        max-height="700"
      >
        <v-row dense>
          <v-col
            v-for="(item, i) in items"
            :key="`wk_${i}`"
            cols="12"
            md="4"
          >
            <v-hover
              v-slot="{ hover }"
              :disabled="!hovered"
              close-delay="120"
            >
              <v-card
                :elevation="hover ? hoverElevation : normalElevation"
                :class="['rounded', 'transition-swing']"
                color="white"
                flat
                tile
              >
                <v-img
                  :aspect-ratio="ratio"
                  :src="item.cover"
                  height="inherit"
                >
                  <div class="d-flex justify-end mt-6 mr-6">
                    <v-btn
                      v-if="hover"
                      text
                      dark
                      class="btn-set-bg"
                      @click="onSet(item)"
                    >
                      应用
                    </v-btn>
                  </div>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div
              class="co-flex-col co-justify-center co-items-center co-border co-border-dash co-border-gray-500 co-w-full co-h-full rounded py-8"
              @click="onUpload"
            >
              <v-icon
                size="64"
                color="blue-grey lighten-3"
              >
                mdi-plus
              </v-icon>
              <span class="co-text-md co-text-gray-500">上传作品</span>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-divider />
      <v-card-actions>
        <v-pagination
          v-model="pagination.current"
          :length="pagination.length"
          :total-visible="pagination.totalVisible"
          @input="changePagination($event)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'

  export default {
    props: {
    },
    data () {
      return {
        btnClose: {
          icon: 'mdi-close'
        },
        dlg: {
          title: '设置封面',
          visible: false
        },
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 9
        },
        ratio: 1.45,
        normalElevation: 0,
        hoverElevation: 18,
        hovered: true,
        params: null,
        items: [],
        userInfo: {}
      }
    },
    created () {
      this.userInfo = user.info.get()
      // this.getContents()
    },
    methods: {
      onUpload (ev) {
        this.dlg.visible = false
        api.page.navigate(this, 'upload')
      },
      onSet (ev) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.userInfo.backgroundImage = ev.cover
            user.info.save(me.userInfo)
            app.emit(app.event.USER_COVER_CHANGE, me.userInfo)
            me.$notify({
              title: '成功提示',
              message: '封面设置成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '错误提示',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        user.info.update({
          data: {
            backgroundImage: ev.cover
          },
          executed
        })
      },
      show () {
        this.getContents()
        this.dlg.visible = true
      },
      onClose () {
        this.dlg.visible = false
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)
      },
      configItems () {
        this.items = []
        for (const i in this.params.items) {
          const src = this.params.items[i] || {}
          const thumbs = src.thumbs || []
          for (const j in thumbs) {
            const cover = thumbs[j]
            const dst = {
              contentId: src.contentId,
              title: src.title,
              cover,
              type: src.type
            }

            this.items.push(dst)
          }
        }
      },
      getContents () {
        const me = this
        const executed = function (res) {
          if (res.status) {
            const data = me.params.data
            const pagination = me.pagination
            const pageSize = parseInt(data.pageSize) || 9
            pagination.length = Math.ceil(res.total / pageSize)
            me.configItems()
          }
        }

        me.params = web.content.getParamsPersonal({
          type: web.comm.ContentTypes.IMAGE,
          executed
        })
        me.params.reload = true
        api.httpx.getItems(me.params)
      },
    }
  }
</script>

<style lang="scss">
.btn-set-bg {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
</style>
